import '@fortawesome/fontawesome-free/js/all'

document.addEventListener('DOMContentLoaded', () =>
  document.body.insertAdjacentHTML('afterbegin', `
    <svg width="0" height="0" tab-index="-1"
      style="position: absolute; left: -100000px; pointer-events none;">
      <radialGradient id="instagramGradient" r="150%" cx="30%" cy="107%">
        <stop stop-color="#fdf497" offset="0" />
        <stop stop-color="#fdf497" offset="0.05" />
        <stop stop-color="#fd5949" offset="0.45" />
        <stop stop-color="#d6249f" offset="0.6" />
        <stop stop-color="#285AEB" offset="0.9" />
      </radialGradient>
    </svg>
  `)
)
